import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import "src/styles/reset.css"
import "src/styles/fonts/font-family.css"
import "src/styles/theme.css"
import "src/styles/layout.css"
import "src/styles/typography.css"

import ErrorBoundary from "./ErrorBoundary"

import { useSetVh } from "utils"

const Layout = ({ children }) => {
  useSetVh()

  return (
    <Container>
      <ErrorBoundary>
        <main>{children}</main>
      </ErrorBoundary>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;
  background: var(--bg-background-color);

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
