import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { TextGroup, Cta, Link, Media } from "components/common"

import { glassStyle } from "utils"

/**
 * @description Default ValueProp
 *
 * @typedef {{
 *  full: Boolean=false
 *  flip: Boolean=false
 *  align: "left" | "right" | "center"
 *  orientation: "vertical" | "horizontal"
 *  hat: String
 *  hatAs: String
 *  title: String
 *  titleAs: String
 *  subtitle: String
 *  subtitleAs: String
 *  isCard: Boolean=false
 *  image: {width: String, height: String, src: String, alt: String, fixed: {height: Number width: Number}, fluid: {sizes: String}}
 *  backgroundImage: {}
 *  ctas: []
 *  ctasTheme: []
 *  href: String
 *  to: String
 *  className: String
 * }}
 *
 * Props
 * @type React.FunctionComponent<Props>
 */

// shadow
// border
// borderRadius

const ValueProp = (props) => {
  const { to, href } = props

  if (to || href) {
    return (
      <Link to={to} href={href}>
        <Vp {...props} />
      </Link>
    )
  }

  return <Vp {...props} />
}

const Vp = ({
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  image,
  media,
  isCard,
  align,
  orientation,
  backgroundImage,
  className,
  to,
  href,
  ctas,
  ctasTheme,
  full,
  flip,
  style,
  custom,
}) => {
  const hasLink = href || to

  return (
    <StyledValueProp
      className={className}
      isCard={isCard}
      orientation={orientation}
      full={full}
      flip={flip}
      style={style}
    >
      {full && (
        <StyledFullImage isCard={isCard} flip={flip} orientation={orientation}>
          <Media className="fullImage" media={media} />
        </StyledFullImage>
      )}

      <ContentWrap
        className="vp_contentWrap"
        orientation={orientation}
        isCard={isCard}
        full={full}
        align={align}
        flip={flip}
      >
        {media && !full && (
          <StyledImage
            className="image"
            align={align}
            orientation={orientation}
            flip={flip}
          >
            <Media media={media} />
          </StyledImage>
        )}
        <TextWrap
          className="textWrap"
          align={align}
          orientation={orientation}
          flip={flip}
          full={full}
        >
          <TextGroup
            hat={hat}
            hatAs={hatAs}
            titleAs={titleAs}
            title={title}
            subtitle={subtitle}
            subtitleAs={subtitleAs}
            align={
              orientation === "horizontal" ? (flip ? "right" : "left") : align
            }
          />
          {ctas && (
            <CTAWrap className="cta" align={align}>
              <Cta notLink={hasLink} ctas={ctas} theme={ctasTheme} />
            </CTAWrap>
          )}
        </TextWrap>
      </ContentWrap>

      {custom && <div className="customWrap">{custom()}</div>}
    </StyledValueProp>
  )
}

const ALIGN_LIB = {
  left: "flex-start",
  center: "center",
  right: "flex-end",
}

const StyledValueProp = styled.li`
  display: inline-grid;
  list-style-type: none;
  position: relative;
  background: var(--vp-background-color);

  grid-template-columns: ${({ orientation, full }) =>
    full && orientation === "horizontal" ? "1fr 1fr" : "1fr"};

  grid-gap: ${({ full, orientation, isCard }) =>
    !isCard && full && orientation === "horizontal" && "var(--sp-32)"};

  /* Card */
  ${({ isCard }) => isCard && glassStyle}
`

const orientationLib = {
  horizontal: "auto 1fr",
  vertical: "1fr",
}

const StyledFullImage = styled.div`
  width: 100%;

  ${({ flip, orientation }) =>
    orientation === "horizontal"
      ? flip
        ? `
  border-top-right-radius: var(--card-radius);
  border-bottom-right-radius: var(--card-radius);
  `
        : `
        
  border-top-left-radius: var(--card-radius);
  border-bottom-left-radius: var(--card-radius);
  `
      : flip
      ? `
      
      border-bottom-left-radius: var(--card-radius);
      border-bottom-right-radius: var(--card-radius);
      `
      : `    
    border-top-left-radius: var(--card-radius);
    border-top-right-radius: var(--card-radius);
  `};

  margin-bottom: ${({ isCard }) => !isCard && "var(--sp-16)"};

  order: ${({ flip }) => (flip ? 2 : 1)};

  .fullImage {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover !important;

    ${({ flip, orientation }) =>
      orientation === "horizontal"
        ? flip
          ? `
  border-top-right-radius: var(--card-radius);
  border-bottom-right-radius: var(--card-radius);
  `
          : `
        
  border-top-left-radius: var(--card-radius);
  border-bottom-left-radius: var(--card-radius);
  `
        : flip
        ? `
      
      border-bottom-left-radius: var(--card-radius);
      border-bottom-right-radius: var(--card-radius);
      `
        : `    
    border-top-left-radius: var(--card-radius);
    border-top-right-radius: var(--card-radius);
  `};
  }
`

const ContentWrap = styled.div`
  display: grid;
  grid-gap: ${({ orientation, isCard }) =>
    !isCard && orientation === "horizontal" ? "var(--sp-16)" : "var(--sp-8)"};
  grid-template-columns: ${({ orientation }) => orientationLib[orientation]};
  order: ${({ flip }) => (flip ? 1 : 2)};
  padding: ${({ isCard }) => isCard && `var(--card-padding)`};
  /* box-shadow: ${({ isCard }) => isCard && `var(--card-shadow)`}; */

  align-content: flex-start;

  ${({ isCard, orientation, flip }) =>
    isCard && orientation === "horizontal"
      ? flip
        ? `
    border-bottom-left-radius: var(--card-radius);
    border-top-left-radius: var(--card-radius);
    `
        : `
    border-bottom-right-radius: var(--card-radius);
    border-top-right-radius: var(--card-radius);
    `
      : `
    border-bottom-left-radius: var(--card-radius);
    border-bottom-right-radius: var(--card-radius);
    `};
`

const TextWrap = styled.div`
  text-align: ${({ align, orientation }) =>
    orientation === "horizontal" ? "left" : align};
  --tg-grid-gap: var(--sp-4);

  align-self: ${({ full, orientation, align }) =>
    full && orientation === "horizontal" && ALIGN_LIB[align]};

  order: ${({ flip }) => (flip ? 1 : 2)};

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    --subtitle-font-weight: 500;
    --tg-subtitle-color: var(--soil);
    line-height: 24px;
  }
`

const CTAWrap = styled.div`
  margin-top: var(--sp-16);
  text-align: ${({ align }) => align};
`

const StyledImage = styled.div`
  display: inline-block;
  align-self: ${({ align, orientation }) =>
    orientation === "horizontal" && ALIGN_LIB[align]};
  text-align: ${({ align, orientation }) =>
    orientation === "vertical" && align};

  order: ${({ flip }) => (flip ? 2 : 1)};

  img {
    /* display: block; // i dunno why but this aligns its up.::after.. */
  }
`

ValueProp.defaultProps = {
  full: false,
  flip: false,
  align: "left",
  orientation: "vertical",
  hat: "",
  hatAs: "h6",
  title: "",
  titleAs: "h5",
  subtitle: "",
  subtitleAs: "p",
  isCard: false,
  icon: null,
  backgroundImage: null,
  cta: null,
  href: "",
  to: "",
}
ValueProp.propTypes = {
  full: PropTypes.bool.isRequired,
  flip: PropTypes.bool.isRequired,
  align: PropTypes.oneOf(["left", "center", "right"]).isRequired,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  hasAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "pSmall"]),
  titleAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "pSmall"]),
  subtitleAs: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "pSmall",
  ]),
  isCard: PropTypes.bool.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    file: PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string }),
  }),
  backgroundImage: PropTypes.object,
  ctas: PropTypes.arrayOf(PropTypes.object),
  ctasTheme: PropTypes.arrayOf(PropTypes.object),
  href: PropTypes.string,
  to: PropTypes.string,
}

export default ValueProp
