import React from "react"
import map from "lodash/map"
import styled from "styled-components"
import PropTypes from "prop-types"

import Button from "./Button"
import Link from "./Link"

/**
 * @description Contentful CTA for Templates
 * @typedef {{
 *  ctas: [{text: String, url: String to: String, tracking: {}}]
 *  theme: [{theme: 'default' | 'black', small: false, isLink: false}]
 * }} Props
 *
 * @type React.FunctionComponent<Props>
 */

const CTA = ({ ctas, theme, notLink, ...other }) => {
  return (
    <StyledCTA ctasLength={ctas && ctas.length} {...other}>
      {map(ctas, ({ text, id, to, url, tracking }, idx) => {
        const btnTheme = theme.length && theme[idx]

        if (btnTheme.isLink) {
          return (
            <Link notLink={notLink} to={to} href={url} key={id} arrow>
              {text}
            </Link>
          )
        }
        if (to) {
          return (
            <Link notLink={notLink} to={to} key={id}>
              <Button {...btnTheme} tracking={tracking}>
                {text}
              </Button>
            </Link>
          )
        }

        if (url) {
          return (
            <Link
              notLink={notLink}
              href={url}
              key={id}
              target="_blank"
              rel="noreferrer"
            >
              <Button {...btnTheme} tracking={tracking}>
                {text}
              </Button>
            </Link>
          )
        }

        return (
          <Button {...btnTheme} key={id} tracking={tracking}>
            {text}
          </Button>
        )
      })}
    </StyledCTA>
  )
}

const StyledCTA = styled.div`
  display: inline-grid;
  grid-template-columns: ${({ ctasLength }) => `repeat(${ctasLength}, auto)`};
  grid-gap: var(--cta-grid-gap);
  align-items: center;
`

CTA.defaultProps = {
  ctas: [],
  theme: [],
}

CTA.propTypes = {
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  theme: PropTypes.array,
}

export default CTA
