import React, { useCallback } from "react"
import { Link as GLink } from "gatsby"
import styled from "styled-components"
// import { OutboundLink } from "gatsby-plugin-google-gtag"

import { useTracking } from "utils"

// import OutboundLink from "components/common/OutboundLink"

import Arrow from "images/common/arrow.inline.svg"

/**
 * Link
 * @param {object} props
 * @param {string} props.href
 * @param {string} props.to
 * @param {string} props.activeClassName
 * @param {boolean} props.arrow
 * @param {boolean} props.underline
 * @param {object} props.track
 * @param {String} props.track.category
 *
 * @example
 * <Link to="/">Test</Button>
 */

const Link = ({
  to,
  href,
  underline,
  arrow,
  children,
  activeClassName,
  track,
  notLink,
  ...other
}) => {
  const [trackInternal] = useTracking({ ...track })

  const renderLink = useCallback(() => {
    if (notLink) {
      return (
        <span className="notLink" {...other}>
          {children}
        </span>
      )
    }

    if (to) {
      const hasSlash = to.match(/\/+$/)
      const toWithSlash = hasSlash ? to : `${to}/`

      return (
        <GLink
          to={toWithSlash}
          activeClassName={activeClassName}
          onClick={() => trackInternal()}
          {...other}
        >
          {children}
        </GLink>
      )
    }
    return (
      <a className="outbound" href={href} track={track} {...other}>
        {children}
      </a>
    )
  }, [
    to,
    href,
    children,
    other,
    activeClassName,
    trackInternal,
    track,
    notLink,
  ])

  if (arrow) {
    return (
      <StyledLink underline={underline}>
        {renderLink()}
        <Arrow alt="svg arrow" className="arrow" />
      </StyledLink>
    )
  }

  return renderLink()
}

const StyledLink = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};

  .arrow {
    margin-left: var(--sp-8);
    transition: 0.1s all ease-in-out;
    transition-property: opacity, transform;
  }

  &:hover {
    opacity: 0.8;
    .arrow {
      transform: translateX(var(--sp-8));
    }
  }
`

Link.defaultProps = {
  to: "",
  href: "",
  arrow: false,
  underline: false,
}

export default Link
