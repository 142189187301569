import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import map from "lodash/map"

import { Link, Media } from "components/common"
import { device } from "src/utils"
// import { device } from "utils"

const Nav = () => {
  const {
    nav: { logo, navigation },
  } = useStaticQuery(NAV_QUERY)

  return (
    <NavWrap>
      <Link to="/">
        <Media media={logo} />
      </Link>

      <ul className="nav-links">
        {map(navigation, ({ id, text, href }) => (
          <li key={id}>
            <Link href={href}>{text}</Link>
          </li>
        ))}
      </ul>
    </NavWrap>
  )
}

const NavWrap = styled.nav`
  padding: var(--sp-40);
  display: flex;
  justify-content: space-between;

  .nav-links {
    display: none;
    @media ${device.laptop} {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, max-content));
    }

    a {
      color: #fefefe;
      font-size: 0.875rem;
      line-height: 1;
      transition: 0.2s opacity ease-in-out;
      letter-spacing: 0.08em;
      font-weight: 700;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`

export const NAV_QUERY = graphql`
  {
    nav: contentfulNavigation(navigationId: { eq: "nav" }) {
      ...Navigation
    }
    # mobile_nav: contentfulNavigation(navigationId: { eq: "mobile_nav" }) {
    #   ...Navigation
    # }
  }
`

export default Nav
